.l-footer {
	background: white;
	box-sizing: border-box;
	width: 100%;
	text-align: center;
	padding: 40px 0;
	font-size: 1.6rem;
	letter-spacing: 0.120em;
	margin-top: 4em;
	font-weight: bold;
}
