.c-btn {
	background-size: cover;
	display: inline-block;
	color: transparent;

	&:hover {
		color: transparent;
	}

	&--viewmore {
		background-image: url(../images/img-btn-more.svg);
		width: 216px;
		height: 44px;
	}

	&--moongift {
		background-image: url(../images/img-btn-moongift.svg);
		width: 216px;
		height: 44px;
	}

	&--devrel {
		background-image: url(../images/img-btn-devrel.svg);
		width: 216px;
		height: 44px;
	}

	&--contact {
		background-image: url(../images/img-btn-contact.svg);
		width: 216px;
		height: 44px;
	}

}



