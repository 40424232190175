@charset "UTF-8";
@font-face {
  font-family: 'Artegra-Sans';
  src: url("assets/fonts/Artegra_Sans-100-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Artegra-Sans';
  src: url("assets/fonts/Artegra_Sans-200-ExtraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Artegra-Sans';
  src: url("assets/fonts/Artegra_Sans-300-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

html {
  font-size: 10px;
  min-width: 100%;
}

body {
  font-size: 1.4rem;
  font-family: "DIN Condensed", Arial, Helvetica,  'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', "游ゴシック", YuGothic, Meiryo, sans-serif;
}

a {
  transition: all .3s ease;
  color: #264ea0;
}

a:hover {
  color: #264ea0;
  cursor: pointer;
}

.l-header {
  box-sizing: border-box;
  height: 80px;
  width: 100%;
  text-align: center;
  background-color: #fff;
  z-index: 10;
  position: fixed;
}

@media screen and (max-width: 768px) {
  .l-header {
    height: 60px;
  }
}

.l-header__icon-logo {
  position: fixed;
  left: 16px;
  top: 8px;
  z-index: 100;
  width: 40px;
  height: auto;
}

.l-header__lang {
  display: noe;
  font-size: 2rem;
  line-height: 60px;
  height: 60px;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .l-header__lang {
    display: inline-block;
  }
}

.l-header__spmenu-btn {
  display: none;
}

@media screen and (max-width: 768px) {
  .l-header__spmenu-btn {
    position: fixed;
    top: 0;
    right: 0;
    display: block;
    height: 60px;
    width: 60px;
    background-image: linear-gradient(0deg, #56bedf 0%, #264d9f 100%);
    z-index: 100;
  }
}

.l-header__inner {
  box-sizing: border-box;
  width: 88%;
  height: 80px;
  max-width: 88%;
  margin: 0 auto;
  text-align: left;
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
}

@media screen and (max-width: 768px) {
  .l-header__inner {
    display: none;
  }
  .l-header__inner.is-on {
    display: block;
    flex-direction: column;
    min-width: 100%;
    width: 100%;
    height: 100vh;
    background-color: black;
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
  }
}

.l-header__logo-wrapper {
  width: 25%;
  max-width: 25%;
  text-align: left;
}

.l-header__logo {
  width: 100%;
  min-width: 200px;
  max-width: 240px;
  vertical-align: middle;
}

.l-header__nav {
  text-align: right;
  white-space: nowrap;
  flex-grow: 1;
}

@media screen and (max-width: 768px) {
  .l-header__nav {
    text-align: center;
    white-space: pre-line;
  }
}

.l-header__navlist {
  font-size: 2.4rem;
  padding: 0;
  margin: 0;
}

@media screen and (max-width: 768px) {
  .l-header__navlist {
    margin-top: 80px;
  }
}

.l-header__navlist-item {
  display: inline-block;
  text-align: center;
  padding: 0 1rem;
  z-index: 3;
  position: relative;
  height: 80px;
  line-height: 80px;
  font-size: 2rem;
  letter-spacing: 0.120em;
}

@media screen and (max-width: 768px) {
  .l-header__navlist-item {
    line-height: 40px;
    height: 40px;
    color: white;
  }
}

.l-header__navlist-item a, .l-header__navlist-item span {
  color: #646464;
  text-decoration: none;
}

.l-header__navlist-item a:hover, .l-header__navlist-item span:hover {
  color: #2272c7;
  cursor: pointer;
}

.l-header__navlist-item:last-child {
  padding-right: 0;
}

.l-header__submenu {
  position: absolute;
  left: 0;
  width: 100%;
  margin: 0;
  background: white;
  padding: 2rem;
  height: calc(100vh - 108px);
  background: linear-gradient(180deg, white 15%, rgba(255, 255, 255, 0) 30%);
}

.l-header__submenu-logo-wrapper::after {
  display: block;
  position: absolute;
  content: "";
  background: linear-gradient(0deg, #dbdef8 0%, #7080f1 100%);
  width: 75%;
  height: 3px;
  margin-top: 1em;
  opacity: 0;
  transition: .3s ease-in-out;
}

.l-header__submenu-logo-wrapper:hover::after {
  opacity: 1;
}

.l-header__submenu-logo {
  max-width: 100%;
  width: 85%;
}

.l-header__submenu-logo::after {
  display: block;
  content: "";
  background: linear-gradient(0deg, #dbdef8 0%, #7080f1 100%);
  width: 75%;
  height: 3px;
}

@media screen and (min-width: 769px) {
  .ls-header {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .ls-header {
    display: block;
    box-sizing: border-box;
    height: 66px;
    width: 100%;
    text-align: center;
    background-color: #fff;
    z-index: 10;
    position: fixed;
    top: 0;
  }
  .ls-header__inner {
    box-sizing: border-box;
    width: 88%;
    height: 66px;
    max-width: 88%;
    margin: 0 auto;
    text-align: left;
    display: inline-flex;
  }
  .ls-header__logo-wrapper {
    line-height: 100px;
    flex-grow: 1;
    line-height: 66px;
  }
  .ls-header__logo {
    height: 50px;
    width: auto;
    vertical-align: middle;
  }
  .ls-header__humburger {
    align-self: center;
  }
}

.l-main {
  padding-top: 80px;
}

@media screen and (max-width: 768px) {
  .l-main {
    padding-top: 66px;
  }
}

.l-footer {
  background: white;
  box-sizing: border-box;
  width: 100%;
  text-align: center;
  padding: 40px 0;
  font-size: 1.6rem;
  letter-spacing: 0.120em;
  margin-top: 4em;
  font-weight: bold;
}

.c-media {
  margin-bottom: 1em;
}

@media screen and (max-width: 768px) {
  .c-media {
    margin-bottom: 4em;
  }
}

.c-media__img-wrapper {
  margin-bottom: 1em;
}

.c-media__img {
  width: 100%;
  height: auto;
}

.c-media__text {
  line-height: 1.7;
  font-size: 1.6rem;
  letter-spacing: 0.120em;
}

.c-btn {
  background-size: cover;
  display: inline-block;
  color: transparent;
}

.c-btn:hover {
  color: transparent;
}

.c-btn--viewmore {
  background-image: url(../images/img-btn-more.svg);
  width: 216px;
  height: 44px;
}

.c-btn--moongift {
  background-image: url(../images/img-btn-moongift.svg);
  width: 216px;
  height: 44px;
}

.c-btn--devrel {
  background-image: url(../images/img-btn-devrel.svg);
  width: 216px;
  height: 44px;
}

.c-btn--contact {
  background-image: url(../images/img-btn-contact.svg);
  width: 216px;
  height: 44px;
}

.c-table__row {
  height: 4em;
}

.c-table__th {
  padding: 0 0.5em;
  min-width: 100px;
}

.c-table__td, .c-table__th {
  letter-spacing: 0.120em;
  font-family: Arial, Helvetica,  'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', "游ゴシック", YuGothic, Meiryo, sans-serif;
}

@media screen and (max-width: 768px) {
  .c-table__th {
    font-size: 1.4rem;
  }
}

.c-sidebar {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
}

@media screen and (max-width: 768px) {
  .c-sidebar {
    display: none;
  }
}

.c-sidebar__lang {
  color: white;
  width: 80px;
  height: 80px;
  line-height: 80px;
  font-size: 2.6rem;
  letter-spacing: 0.120em;
  background-image: linear-gradient(0deg, #56bedf 0%, #264d9f 100%);
}

.c-sidebar__lang span {
  display: inline-block;
  width: 100%;
  height: 100%;
  text-align: center;
}

.c-sidebar__lang:hover {
  cursor: pointer;
}

.c-sidebar__logo {
  width: calc(80px/2);
  height: auto;
  margin: 60px 0 30% 0;
}

.c-sidebar__copyright {
  margin-bottom: 80px;
}

.c-sidebar__copyright, .c-sidebar__sns {
  writing-mode: vertical-rl;
  color: #646464;
  line-height: 80px;
}

.c-sidebar__twitter, .c-sidebar__facebook {
  margin: 1em 0;
}

.c-hamburger {
  width: 30px;
  height: 26px;
  transition: all .3s;
  margin: 0 auto;
  position: relative;
  display: block;
  margin-top: 16px;
}

.c-hamburger span {
  position: absolute;
  display: inline-block;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: white;
  border-radius: 2px;
  -webkit-transition: all .4s;
  transition: all .4s;
  box-sizing: border-box;
}

.c-hamburger span:nth-of-type(1) {
  top: 0;
}

.c-hamburger span:nth-of-type(2) {
  top: 12px;
}

.c-hamburger span:nth-of-type(3) {
  bottom: 0;
}

.c-hamburger span:nth-of-type(2)::after {
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  width: 100%;
  height: 2px;
  background-color: white;
  border-radius: 2px;
  -webkit-transition: all .4s;
  transition: all .4s;
}

.is-on span:nth-of-type(2) {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.is-on span:nth-of-type(2)::after {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.is-on span:nth-of-type(1) {
  -webkit-transform: translateY(20px) scale(0);
  -ms-transform: translateY(20px) scale(0);
  transform: translateY(20px) scale(0);
}

.is-on span:nth-of-type(3) {
  -webkit-transform: translateY(-20px) scale(0);
  -ms-transform: translateY(-20px) scale(0);
  transform: translateY(-20px) scale(0);
}

.tp-hero {
  display: flex;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 80px);
}

.tp-hero__img-wrapper {
  align-self: center;
}

.tp-hero__logo {
  width: 309px;
  height: 628px;
}

@media screen and (max-width: 768px) {
  .tp-hero__logo {
    height: 240px;
    width: auto;
  }
}

.tp-section-header {
  position: relative;
  margin-bottom: 1em;
  white-space: nowrap;
}

@media screen and (max-width: 768px) {
  .tp-section-header {
    text-align: center;
    margin-bottom: 2em;
  }
}

.tp-section-header::before {
  background: linear-gradient(#56bedf 0%, #264d9f 100%);
  width: 6px;
  height: 4em;
  content: "";
  display: inline-block;
  float: left;
  margin-right: 10px;
}

@media screen and (max-width: 768px) {
  .tp-section-header::before {
    display: none;
  }
}

.tp-section-header__title {
  font-size: 4rem;
  letter-spacing: 0.120em;
  line-height: 1.1;
  margin-bottom: 0;
}

.tp-section-header__title-ja {
  font-size: 1.2rem;
  letter-spacing: 0.120em;
  color: #646464;
}

.tp-section {
  padding: 4em 0;
}

.tp-section--gray {
  background-color: #f6f6f6;
}

.tp-section__text {
  line-height: 2;
  letter-spacing: 0.120em;
  font-size: 1.6rem;
}

@media screen and (max-width: 768px) {
  .tp-section--service, .tp-section--vision {
    padding-bottom: 0;
  }
}

.tp-section .c-btn {
  display: block;
  margin: 0 auto;
}

.tp-news-list {
  padding: 0;
}

.tp-news-list__item {
  list-style: none;
  margin-bottom: 2em;
  padding-bottom: 1em;
  border-bottom: dashed 2px #d2d2d2;
}

.tp-news-list__link {
  display: block;
  font-size: 2.4rem;
}

@media screen and (max-width: 768px) {
  .tp-news-list__link {
    font-size: 1.8rem;
  }
}

.tp-news-list__date {
  color: #646464;
  font-size: 1.2rem;
  letter-spacing: 0.120em;
}

.tp-news-list__label {
  background-color: #646464;
  color: white;
  font-size: 1.2rem;
  padding: 0 0.2em;
  margin: 0 0.2em;
  display: inline-block;
  vertical-align: middle;
  border-radius: 2px;
  line-height: 1.7;
}

.tp-header {
  font-size: 3.4rem;
  letter-spacing: 0.120em;
  margin-bottom: 1em;
}

@media screen and (max-width: 768px) {
  .tp-header {
    font-size: 2.2rem;
    line-height: 1.7;
  }
}

.tp-member-list {
  padding: 0;
}

.tp-member-list__item {
  list-style: none;
}

.tp-member-list__box {
  margin: 0 10%;
}

@media screen and (max-width: 768px) {
  .tp-member-list__box {
    margin-bottom: 2em;
  }
}

.tp-member-list__img {
  max-width: 100%;
  height: auto;
}

.tp-member-list__name {
  letter-spacing: 0.120em;
  margin: 1.5em 0 1em 0;
}

.tp-member-list__name-ja, .tp-member-list__role {
  letter-spacing: 0.120em;
  margin-bottom: 0;
  line-height: 1.7;
}

.u-align-center {
  text-align: center;
}

.mt0 {
  margin-top: 0rem !important;
}

.mr0 {
  margin-right: 0rem !important;
}

.mb0 {
  margin-bottom: 0rem !important;
}

.ml0 {
  margin-left: 0rem !important;
}

.pt0 {
  padding-top: 0rem !important;
}

.pr0 {
  padding-right: 0rem !important;
}

.pb0 {
  padding-bottom: 0rem !important;
}

.pl0 {
  padding-left: 0rem !important;
}

.mt1 {
  margin-top: 1rem !important;
}

.mr1 {
  margin-right: 1rem !important;
}

.mb1 {
  margin-bottom: 1rem !important;
}

.ml1 {
  margin-left: 1rem !important;
}

.pt1 {
  padding-top: 1rem !important;
}

.pr1 {
  padding-right: 1rem !important;
}

.pb1 {
  padding-bottom: 1rem !important;
}

.pl1 {
  padding-left: 1rem !important;
}

.mt2 {
  margin-top: 2rem !important;
}

.mr2 {
  margin-right: 2rem !important;
}

.mb2 {
  margin-bottom: 2rem !important;
}

.ml2 {
  margin-left: 2rem !important;
}

.pt2 {
  padding-top: 2rem !important;
}

.pr2 {
  padding-right: 2rem !important;
}

.pb2 {
  padding-bottom: 2rem !important;
}

.pl2 {
  padding-left: 2rem !important;
}

.mt3 {
  margin-top: 3rem !important;
}

.mr3 {
  margin-right: 3rem !important;
}

.mb3 {
  margin-bottom: 3rem !important;
}

.ml3 {
  margin-left: 3rem !important;
}

.pt3 {
  padding-top: 3rem !important;
}

.pr3 {
  padding-right: 3rem !important;
}

.pb3 {
  padding-bottom: 3rem !important;
}

.pl3 {
  padding-left: 3rem !important;
}

.mt4 {
  margin-top: 4rem !important;
}

.mr4 {
  margin-right: 4rem !important;
}

.mb4 {
  margin-bottom: 4rem !important;
}

.ml4 {
  margin-left: 4rem !important;
}

.pt4 {
  padding-top: 4rem !important;
}

.pr4 {
  padding-right: 4rem !important;
}

.pb4 {
  padding-bottom: 4rem !important;
}

.pl4 {
  padding-left: 4rem !important;
}

.mt5 {
  margin-top: 5rem !important;
}

.mr5 {
  margin-right: 5rem !important;
}

.mb5 {
  margin-bottom: 5rem !important;
}

.ml5 {
  margin-left: 5rem !important;
}

.pt5 {
  padding-top: 5rem !important;
}

.pr5 {
  padding-right: 5rem !important;
}

.pb5 {
  padding-bottom: 5rem !important;
}

.pl5 {
  padding-left: 5rem !important;
}

.mt6 {
  margin-top: 6rem !important;
}

.mr6 {
  margin-right: 6rem !important;
}

.mb6 {
  margin-bottom: 6rem !important;
}

.ml6 {
  margin-left: 6rem !important;
}

.pt6 {
  padding-top: 6rem !important;
}

.pr6 {
  padding-right: 6rem !important;
}

.pb6 {
  padding-bottom: 6rem !important;
}

.pl6 {
  padding-left: 6rem !important;
}

.mt7 {
  margin-top: 7rem !important;
}

.mr7 {
  margin-right: 7rem !important;
}

.mb7 {
  margin-bottom: 7rem !important;
}

.ml7 {
  margin-left: 7rem !important;
}

.pt7 {
  padding-top: 7rem !important;
}

.pr7 {
  padding-right: 7rem !important;
}

.pb7 {
  padding-bottom: 7rem !important;
}

.pl7 {
  padding-left: 7rem !important;
}

.mt8 {
  margin-top: 8rem !important;
}

.mr8 {
  margin-right: 8rem !important;
}

.mb8 {
  margin-bottom: 8rem !important;
}

.ml8 {
  margin-left: 8rem !important;
}

.pt8 {
  padding-top: 8rem !important;
}

.pr8 {
  padding-right: 8rem !important;
}

.pb8 {
  padding-bottom: 8rem !important;
}

.pl8 {
  padding-left: 8rem !important;
}

.mt9 {
  margin-top: 9rem !important;
}

.mr9 {
  margin-right: 9rem !important;
}

.mb9 {
  margin-bottom: 9rem !important;
}

.ml9 {
  margin-left: 9rem !important;
}

.pt9 {
  padding-top: 9rem !important;
}

.pr9 {
  padding-right: 9rem !important;
}

.pb9 {
  padding-bottom: 9rem !important;
}

.pl9 {
  padding-left: 9rem !important;
}

.mt10 {
  margin-top: 10rem !important;
}

.mr10 {
  margin-right: 10rem !important;
}

.mb10 {
  margin-bottom: 10rem !important;
}

.ml10 {
  margin-left: 10rem !important;
}

.pt10 {
  padding-top: 10rem !important;
}

.pr10 {
  padding-right: 10rem !important;
}

.pb10 {
  padding-bottom: 10rem !important;
}

.pl10 {
  padding-left: 10rem !important;
}
