.tp-hero {
	display: flex;
	justify-content: center;
	width: 100%;
	height: calc(100vh - #{$header-height});

	@include mq() {
		// height: calc (100vh - 66px);
	}

	&__img-wrapper {
		align-self: center;
	}

	&__logo {
		width: 309px;
		height: 628px;
		@include mq() {
			height: 240px;
			width: auto;
		}		
	}

}

.tp-section-header {
	position: relative;
	margin-bottom: 1em;
	white-space: nowrap;
	@include mq() {
		text-align: center;
		margin-bottom: 2em;
	}

	&::before {
		background: linear-gradient(rgb(86,190,223) 0%, rgb(38,77,159) 100%);
		width: 6px;
		height: 4em;
		content: "";
		display: inline-block;
		float: left;
		margin-right: 10px;
		
		@include mq() {
			display: none;
		}
	}

	&__title {
    font-size: 4rem;
    letter-spacing: 0.120em;
    line-height: 1.1;
    margin-bottom: 0;
	}

	&__title-ja {
		font-size: 1.2rem;
		letter-spacing: 0.120em;
		color: $color-secondary-text;
	}
}

.tp-section {
	padding: 4em 0;

	&--gray {
		background-color: $color-background-lightest;
	}

	&__text {
		line-height: 2;
		letter-spacing: 0.120em;
		font-size: 1.6rem;
	}

	&--service, &--vision {
		@include mq() {
			padding-bottom: 0;
		}
	}

	.c-btn {
		display: block;
		margin: 0 auto;
	}
}

.tp-news-list {
	padding: 0;

	&__item {
		list-style: none;
		margin-bottom: 2em;
		padding-bottom: 1em;
		border-bottom: dashed 2px $color-background-light;
	}

	&__link {
		display: block;
		font-size: 2.4rem;
		@include mq() {
			font-size: 1.8rem;
		}
	}

	&__date {
		color: $color-secondary-text;
		font-size: 1.2rem;
		letter-spacing: 0.120em;
	}

	&__label {
		background-color: $color-secondary-text;
		color: white;
		font-size: 1.2rem;
		padding: 0 0.2em;
		margin: 0 0.2em;
		display: inline-block;
		vertical-align: middle;
		border-radius: 2px;
		line-height: 1.7;
	}
}

.tp-header {
	font-size: 3.4rem;
	letter-spacing: 0.120em;
	margin-bottom: 1em;

	@include mq() {
		font-size: 2.2rem;
    line-height: 1.7;
	}
}

.tp-member-list {

	padding: 0;

	&__item {
		list-style: none;
	}

	&__box {
		margin: 0 10%;
		@include mq(){
			margin-bottom: 2em;
		}
	}

	&__img {
		max-width: 100%;
		height: auto;
	}

	&__name {
		letter-spacing: 0.120em;
		margin: 1.5em 0 1em 0;
	}

	&__name-ja, &__role {
		letter-spacing: 0.120em;
		margin-bottom: 0;
		line-height: 1.7;
	}
}
