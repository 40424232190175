.c-sidebar {
	position: fixed;
	top: 0;
	right: 0;
	height: 100%;

	@include mq() {
		display: none;
	}

	&__lang {
		color: white;
		width: $header-height;
		height: $header-height;
		line-height: $header-height;
		font-size: 2.6rem;
		letter-spacing: 0.120em;
	  background-image: linear-gradient( 0deg, rgb(86,190,223) 0%, rgb(38,77,159) 100%);
	  span {
	  	display: inline-block;
	  	width: 100%;
	  	height: 100%;
	  	text-align: center;

	  }
	  &:hover {
	  	cursor: pointer;
	  }
	}

	&__logo {
		width: calc(#{$header-height}/2);
		height: auto;
		margin: 60px 0 30% 0;
	}
}

.c-sidebar__copyright {
	margin-bottom: $header-height;
}

.c-sidebar__copyright, .c-sidebar__sns  {
	writing-mode: vertical-rl;
	color: $color-secondary-text;
	line-height: $header-height;
}

.c-sidebar__twitter, .c-sidebar__facebook {
	margin: 1em 0;
}