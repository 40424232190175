.l-header {
	box-sizing: border-box;
	height: $header-height;
	width: 100%;
	text-align: center;
	background-color: #fff;
	z-index: 10;
	position: fixed;

	@include mq() {
		// display: none;
		height: $header-height - 20;
	}

	&__icon-logo {
		position: fixed;
		left: 16px;
		top: 8px;
		z-index: 100;
		width: 40px;
		height: auto;
	}

	&__lang {
		display: noe;
		@include mq() {
			display: inline-block;
		}
		font-size: 2rem;
		line-height: $header-height - 20;
		height: $header-height - 20;
		cursor: pointer;
	}

	&__spmenu-btn {
		display: none;

		@include mq() {
			position: fixed;
			top: 0;
			right: 0;
			display: block;
			height: $header-height - 20;
			width: $header-height - 20;
			background-image: linear-gradient( 0deg, rgb(86,190,223) 0%, rgb(38,77,159) 100%);
			z-index: 100;
		}
	}

	.js-spmenu-btn.is-on {

	}

	&__inner {
		box-sizing: border-box;
		width: $header-inner-width;
		height: $header-height;
		max-width: $header-inner-width;
		margin: 0 auto;
		text-align: left;
		display: inline-flex;
		align-items: center;
		justify-content: flex-end;

		@include mq() {
			display: none;
			&.is-on {
				display: block;
				flex-direction: column;
				min-width: 100%;
				width: 100%;
				height: 100vh;
				background-color: black;
				position: fixed;
				top: 0;
				left: 0;
				overflow: hidden;				
			}
		}
	}


	&__logo-wrapper {
		width: 25%;
		max-width: 25%;
		text-align: left;
	}

	&__logo {
		width: 100%;
		min-width: 200px;
		max-width: 240px;
		vertical-align: middle;
	}

	&__nav {
		text-align: right;
		white-space: nowrap;
		flex-grow:1;
		@include mq() {
			text-align: center;
			white-space: pre-line;
		}
	}

	&__navlist {
		font-size: 2.4rem;
		padding: 0;
		margin: 0;
		@include mq() {
			margin-top: $header-height;
		}
	}

	&__navlist-item {
		display: inline-block;
		text-align: center;
		padding: 0 1rem;
		z-index: 3;
		position: relative;
		height: $header-height;
    line-height: $header-height;
    font-size: 2rem;
    letter-spacing: 0.120em;
		@include mq() {
			line-height: $header-height/2;
			height: $header-height/2;
			color: white;
		}

		a, span {
			color: $color-secondary-text;
			text-decoration: none;
		}

		a:hover, span:hover {
			color: $color-primary;
			cursor: pointer;
		}

		&:last-child {
			padding-right: 0;
		}
	}

	&__navlist__sub {
		// display: none;
	}

	&__submenu {
		position: absolute;
	  left: 0;
	  width: 100%;
	  margin: 0;
	  background: white;
	  padding: 2rem;
	  height: calc(100vh - 108px);
	  background: linear-gradient( 180deg, rgba(255,255,255, 100) 15%, rgba(255,255,255, 0) 30%);
	}

	&__submenu-logo-wrapper {
		&::after {
			display: block;
			position: absolute;
			content: "";
		  background: linear-gradient( 0deg, rgb(219,222,248) 0%, rgb(112,128,241) 100%);
		  width: 75%;
		  height: 3px;
		  margin-top: 1em;
		  opacity: 0;
		  transition: .3s ease-in-out;
		}

		&:hover::after {
			opacity: 1;
		}
	}

	&__submenu-logo {
		max-width: 100%;
		width: 85%;

		&::after {
			display: block;
			content: "";
		  background: linear-gradient( 0deg, rgb(219,222,248) 0%, rgb(112,128,241) 100%);
		  width: 75%;
		  height: 3px;
		}
	}

}